body {
  font-family: 'Lato', sans-serif;
}

.main {
  padding-top: 5em;
  padding-left: 5em;
}

.heading {
  color: #FF6B6B;
  font-weight: bold;
}

.sub-heading {
  color: #A5A5A5;
  font-weight: 300;
}

.image {
  margin-top: 2em;
}

a.btn {
  background-color: #FF6B6B !important;
  border: 0 !important;
  margin-top: 3em;
}
